import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Body from "./component/Body";
import './App.css';

function App() {
  return (
    <>
    <Navbar/>
    <Body/>
    <Footer/>
    </>
  );
}

export default App;
