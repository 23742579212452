import React from 'react'
import Logo from '../images/logo3.png'
import './style.css'
export default function Footer() {
  return (
    <footer className="footer">
            <div className="container-fluid">
                        <div className='d-flex justify-content-center pt-2 '>
                                <i class="fa-brands fa-discord fa-xl  fa-sm-sm "></i>
                                <i class="fa-brands fa-whatsapp fa-xl ms-4 me-4"></i>
                                <i class="fa-brands fa-github fa-xl  me-4"></i>
                                <i class="fa-brands fa-linkedin-in fa-xl  me-4"></i>
                                <i class="fa-brands fa-instagram fa-xl "></i>
                        </div>
                <div className="row justify-content-sm-evenly justify-content-between mt-4">
    {/* ---------------------left-section-footer-------------- */}
                    <div className="left-footer col-6 col-md-4 align-self-center ">
                        <div className='d-flex d-inline flex-row justify-content-start '>
                            <div className="">
                                <img src={Logo} alt="logo" className='logo text-sm-center'/>
                            </div>
                            <div className="footer-name text-center align-self-center">
                            <h4 className='footer-name'>Place<span className='x-logo'>X</span></h4>
                            </div>
                        </div>
                    </div>
                    <div className="center-footer col-6 col-md-4">
                       <div className='col mt-2'>                   
                        <div className='d-flex justify-content-center justify-content-sm-evenly'>
                        <ul className='footerList'>
                            <li className='footer-list'><a className='footer-list-text'>Home</a></li>
                            <li className='footer-list'><a className='footer-list-text'>FAQ</a></li>    
                        </ul>
                        <div className=''>
                        <ul className='footerList'>
                        <li className='footer-list'><a className='footer-list-text'>About</a></li>
                        <li className='footer-list'><a className='footer-list-text'>Contact</a></li>
                            
                        </ul>
                        </div>
                        </div> 
                        </div>
                        </div>
                         </div>
                         </div>
            <div className="bottom-footer conatiner-fluid">
         <div className=" d-flex justify-content-around pt-3" >
            <p id='footer-ending'>© 2022 Sellular All Rights Reserved.</p>
        </div> 
        </div>
    </footer>
  )
}


