import React from 'react'
import "./style.css";
import Gif from "../images/giphy.gif";

function Body() {
  return (
    <div className="body">
        <div className="Image1Container">
            <img src={Gif} className="image1" alt=""/>
        </div>
        <div className="Image2Container">
            <img src={Gif} className="image2" alt=""/>
        </div>

        <div className="body-container">
            <div className="body-title">Sellular</div>
            <div className="event-name">
                <span className="event-name--green">PLACE</span>
                <span className="event-name--white">X</span>
            </div>
            <div className="body-subtitle">GET READY FOR PLACEMENT</div>
            <div className="date">1st September 2022</div>
            <button className="register-button glow-on-hover">
                REGISTER NOW
            </button>
        </div>
    </div>
  );
}

export default Body
