import Logo from "../images/logo3.png";
export default function Navbar() {
  return (
      <div className="mainContainer">
        <div className="logoContainer">
          <img className="logo" src={Logo} alt="logo" />
        </div>
        <div className="titleContainer">
          <h4 className="sell">Sellular</h4>
          <h3 className="title">Place</h3>
          <span className="mrX">X</span>
        </div>
          <button className="button">Register</button>
      </div>
  );
}

